import merge from '~lodash.mergewith'
import { init, Integrations, Replay } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fpublic@sentry.com\u002F1",
    environment:"production",
    release:"baserow-saas-web-frontend@1.32.5@0.2.134@4c43e93a-2469-49b3-b702-58139e7074ae",
    ignoreErrors:["ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications","undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')"],
    denyUrls:[new RegExp("graph\\.facebook\\.com", "i"),new RegExp("connect\\.facebook\\.net\\\u002Fen_US\\\u002Fall\\.js", "i"),new RegExp("eatdifferent\\.com\\.woopra-ns\\.com", "i"),new RegExp("static\\.woopra\\.com\\\u002Fjs\\\u002Fwoopra\\.js", "i"),new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("127\\.0\\.0\\.1:4001\\\u002Fisrunning", "i"),new RegExp("webappstoolbarba\\.texthelp\\.com\\\u002F", "i"),new RegExp("metrics\\.itunes\\.apple\\.com\\.edgesuite\\.net\\\u002F", "i"),new RegExp("translate\\.goog\\\u002F", "i")],
    beforeSend:function(event) {
        const sourceFile = event.extra?.body?.sourceFile || '';
        if (
        // Prevents errors in chrome extensions.
        sourceFile.startsWith('chrome-extension') ||
        // Prevents errors in external kaspersky-labs.com scripts.
        sourceFile.includes('kaspersky-labs.com'))
        {
          return null;
        }
        return event;
      },
    attachProps:true,
    logErrors:true,
    replaysSessionSampleRate:0,
    replaysOnErrorSampleRate:1,
  }

  const { Dedupe } = Integrations

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new Dedupe(),
    new Replay(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
