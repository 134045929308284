<template>
  <div>
    <img
      v-if="$config.BASEROW_AGENCY_CLOUD_52_ID"
      :src="
        'https://www.agencycloud-52.com/' +
        $config.BASEROW_AGENCY_CLOUD_52_ID +
        '.png'
      "
      style="display: none"
    />
    <div v-for="topBar in topBars" :key="topBar.id" class="saas-top-bar">
      <i
        v-if="topBar.icon"
        :class="`saas-top-bar__icon iconoir-${topBar.icon}`"
      ></i>
      {{ topBar.text }}
      <a
        v-if="topBar.link_name && topBar.link_url"
        :href="topBar.link_url"
        class="saas-top-bar__link"
        :rel="topBar.link_url.charAt(0) !== '/' ? 'noopener noreferrer' : ''"
        >{{ topBar.link_name }}</a
      >
    </div>
    <nav v-show="navOpen" class="saas-menu">
      <div class="saas-menu__head">
        <nuxt-link :to="{ name: 'index' }" lass="saas-menu__Logo">
          <img
            class="placeholder__logo-image"
            src="@baserow/modules/core/static/img/logo.svg"
            alt="Baserow logo"
          />
        </nuxt-link>
        <div>
          <a class="saas-menu__close" @click="hideNav()">
            <i class="iconoir-cancel"></i>
          </a>
        </div>
      </div>
      <ul class="saas-menu__items">
        <li
          v-for="homePage in homePages"
          :key="homePage.path"
          class="saas-menu__item"
        >
          <nuxt-link :to="homePage.path" class="saas-menu__link">{{
            homePage.title
          }}</nuxt-link>
        </li>
        <li
          v-for="productPath in productPages"
          :key="productPath.path"
          class="saas-menu__item"
        >
          <nuxt-link :to="productPath.path" class="saas-menu__link">{{
            productPath.title
          }}</nuxt-link>
        </li>
        <li v-if="departmentPages.length > 0" class="saas-menu__item">
          <a
            class="saas-menu__link"
            @click="toggleMenuSub($event, $refs.departmentMenu)"
          >
            Solutions by department
            <i
              class="saas-menu__icon"
              :class="
                inMenuSub($refs.departmentMenu)
                  ? 'iconoir-nav-arrow-down'
                  : 'iconoir-nav-arrow-right'
              "
            ></i>
          </a>
          <ul
            ref="departmentMenu"
            class="saas-menu__items-sub"
            :class="{ hidden: !inMenuSub($refs.departmentMenu) }"
          >
            <li
              v-for="departmentPage in departmentPages"
              :key="departmentPage.path"
            >
              <nuxt-link :to="departmentPage.path">{{
                departmentPage.title
              }}</nuxt-link>
            </li>
          </ul>
        </li>
        <li v-if="industryPages.length > 0" class="saas-menu__item">
          <a
            class="saas-menu__link"
            @click="toggleMenuSub($event, $refs.industryMenu)"
          >
            Solutions by industry
            <i
              class="saas-menu__icon"
              :class="
                inMenuSub($refs.industryMenu)
                  ? 'iconoir-nav-arrow-down'
                  : 'iconoir-nav-arrow-right'
              "
            ></i>
          </a>
          <ul
            ref="industryMenu"
            class="saas-menu__items-sub"
            :class="{ hidden: !inMenuSub($refs.industryMenu) }"
          >
            <li v-for="industryPage in industryPages" :key="industryPage.path">
              <nuxt-link :to="industryPage.path">{{
                industryPage.title
              }}</nuxt-link>
            </li>
          </ul>
        </li>
        <li class="saas-menu__item">
          <nuxt-link :to="{ name: 'pricing' }" class="saas-menu__link"
            >Pricing</nuxt-link
          >
        </li>
        <li class="saas-menu__item">
          <a
            class="saas-menu__link"
            @click="toggleMenuSub($event, $refs.communityMenu)"
          >
            Community
            <i
              class="saas-menu__icon"
              :class="
                inMenuSub($refs.communityMenu)
                  ? 'iconoir-nav-arrow-down'
                  : 'iconoir-nav-arrow-right'
              "
            ></i>
          </a>
          <ul
            ref="communityMenu"
            class="saas-menu__items-sub"
            :class="{ hidden: !inMenuSub($refs.communityMenu) }"
          >
            <li
              v-for="communityPage in communityPages"
              :key="communityPage.path"
            >
              <nuxt-link :to="communityPage.path">Get started</nuxt-link>
            </li>
            <li>
              <a href="https://community.baserow.io/" target="_blank"
                >Find answers</a
              >
            </li>
            <li>
              <a
                href="https://community.baserow.io/c/tips-and-tricks/16"
                target="_blank"
                >Tips and tricks</a
              >
            </li>
            <li>
              <a
                href="https://community.baserow.io/c/made-with-baserow/14"
                target="_blank"
                >#MadeWithBaserow</a
              >
            </li>
          </ul>
        </li>
        <li class="saas-menu__item">
          <a
            class="saas-menu__link"
            @click="toggleMenuSub($event, $refs.developersMenu)"
          >
            Developers
            <i
              class="saas-menu__icon"
              :class="
                inMenuSub($refs.developersMenu)
                  ? 'iconoir-nav-arrow-down'
                  : 'iconoir-nav-arrow-right'
              "
            ></i>
          </a>
          <ul
            ref="developersMenu"
            class="saas-menu__items-sub"
            :class="{ hidden: !inMenuSub($refs.developersMenu) }"
          >
            <li>
              <nuxt-link
                :to="{
                  name: 'dev-docs',
                  params: {
                    path: 'index',
                  },
                }"
                >Getting started</nuxt-link
              >
            </li>
            <li>
              <nuxt-link
                :to="{
                  name: 'dev-docs',
                  params: { path: 'plugins/introduction' },
                }"
                >Creating a plugin</nuxt-link
              >
            </li>
            <li>
              <nuxt-link :to="{ name: 'database-api-docs' }">API</nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="{
                  name: 'dev-docs',
                  params: { path: 'installation/install-with-docker' },
                }"
                >Install with Docker</nuxt-link
              >
            </li>
            <li>
              <a
                :href="$config.PUBLIC_BACKEND_URL + '/api/redoc/'"
                target="_blank"
                >OpenAPI specification</a
              >
            </li>
            <li>
              <a
                href="https://gitlab.com/baserow/baserow"
                rel="noopener noreferrer"
                target="_blank"
                >GitLab repository</a
              >
            </li>
            <li>
              <nuxt-link
                :to="{
                  name: 'dev-docs',
                  params: { path: 'development/development-environment' },
                }"
                >Want to contribute?</nuxt-link
              >
            </li>
          </ul>
        </li>
        <li class="saas-menu__item">
          <a
            class="saas-menu__link"
            @click="toggleMenuSub($event, $refs.resourcesMenu)"
          >
            Resources
            <i
              class="saas-menu__icon"
              :class="
                inMenuSub($refs.resourcesMenu)
                  ? 'iconoir-nav-arrow-down'
                  : 'iconoir-nav-arrow-right'
              "
            ></i>
          </a>
          <ul
            ref="resourcesMenu"
            class="saas-menu__items-sub"
            :class="{ hidden: !inMenuSub($refs.resourcesMenu) }"
          >
            <li>
              <nuxt-link :to="{ name: 'blog' }">Blog</nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="{
                  name: 'blog-filtered',
                  params: { category: 'tutorials' },
                }"
                >Tutorials</nuxt-link
              >
            </li>
            <li>
              <nuxt-link :to="{ name: 'videos' }">Videos</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'user-docs' }">Knowledge base</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'templates' }">Templates</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'academy' }">Academy</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'about-us' }">About us</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'contact' }">Contact us</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'faq' }">FAQ</nuxt-link>
            </li>
            <li>
              <nuxt-link :to="{ name: 'partners' }">Partners</nuxt-link>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="saas-menu__items">
        <li class="saas-menu__item">
          <nuxt-link :to="{ name: 'contact-sales' }" class="saas-menu__link"
            >Contact sales</nuxt-link
          >
        </li>

        <li v-if="!isAuthenticated" class="saas-menu__item">
          <Button
            tag="nuxt-link"
            :to="{ name: 'login' }"
            type="secondary"
            size="large"
            >Login</Button
          >
        </li>
        <li v-if="!isAuthenticated" class="saas-menu__item">
          <Button tag="nuxt-link" :to="{ name: 'signup' }" size="large"
            >Sign up</Button
          >
        </li>
        <li v-if="isAuthenticated" class="saas-menu__item">
          <Button
            tag="nuxt-link"
            :to="{ name: 'dashboard' }"
            type="secondary"
            size="large"
            >My dashboard</Button
          >
        </li>
      </ul>
    </nav>
    <header class="saas-header">
      <div class="saas-header__logo">
        <nuxt-link :to="{ name: 'index' }">
          <img
            class="saas-header__logo-image"
            src="@baserow/modules/core/static/img/logo.svg"
            alt="Baserow logo"
            height="29"
          />
        </nuxt-link>
      </div>
      <nav class="saas-header__nav">
        <ul class="saas-header__nav-list">
          <li class="saas-header__nav-item">
            <a
              class="saas-header__nav-link"
              :class="{
                'saas-header__nav-link--open': openSub === $refs.productSub,
              }"
              @click="toggleSub($event, $refs.productSub)"
            >
              Platform
              <i class="saas-header__nav-link-more iconoir-nav-arrow-down"></i>
            </a>
            <div
              ref="productSub"
              class="saas-header__sub"
              :class="{
                'saas-header__sub--open': openSub === $refs.productSub,
              }"
            >
              <div class="saas-header__sub-section">
                <div
                  class="saas-header__sub-title saas-header__sub-title--indent"
                >
                  Discover Baserow
                </div>
                <div class="saas-header__sub-navs">
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="productPage in productPagesTwoChunks[0]"
                      :key="productPage.path"
                      :icon="productPage.icon_class_name || 'iconoir-building'"
                      :title="productPage.title"
                      :description="productPage.navigation_description || ''"
                      :to="productPage.path"
                    ></HeaderSubNavItem>
                  </ul>
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="productPage in productPagesTwoChunks[1]"
                      :key="productPage.path"
                      :icon="productPage.icon_class_name || 'iconoir-building'"
                      :title="productPage.title"
                      :description="productPage.navigation_description || ''"
                      :to="productPage.path"
                    ></HeaderSubNavItem>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="saas-header__nav-item">
            <a
              class="saas-header__nav-link"
              :class="{
                'saas-header__nav-link--open': openSub === $refs.solutionsSub,
              }"
              @click="toggleSub($event, $refs.solutionsSub)"
            >
              Solutions
              <i class="saas-header__nav-link-more iconoir-nav-arrow-down"></i>
            </a>
            <div
              ref="solutionsSub"
              class="saas-header__sub"
              :class="{
                'saas-header__sub--open': openSub === $refs.solutionsSub,
              }"
            >
              <div
                v-if="departmentPages.length > 0"
                class="saas-header__sub-section"
              >
                <div
                  class="saas-header__sub-title saas-header__sub-title--indent"
                >
                  Departments
                </div>
                <div class="saas-header__sub-navs">
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="departmentPage in departmentPagesTwoChunks[0]"
                      :key="departmentPage.path"
                      :icon="
                        departmentPage.icon_class_name || 'iconoir-building'
                      "
                      :title="departmentPage.title"
                      :to="departmentPage.path"
                    ></HeaderSubNavItem>
                  </ul>
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="departmentPage in departmentPagesTwoChunks[1]"
                      :key="departmentPage.path"
                      :icon="
                        departmentPage.icon_class_name || 'iconoir-building'
                      "
                      :title="departmentPage.title"
                      :to="departmentPage.path"
                    ></HeaderSubNavItem>
                  </ul>
                </div>
              </div>
              <div
                v-if="industryPages.length > 0"
                class="saas-header__sub-section"
              >
                <div
                  class="saas-header__sub-title saas-header__sub-title--indent"
                >
                  Industries
                </div>
                <div class="saas-header__sub-navs">
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="industryPage in industryPagesTwoChunks[0]"
                      :key="industryPage.path"
                      :icon="industryPage.icon_class_name || 'iconoir-building'"
                      :title="industryPage.title"
                      :to="industryPage.path"
                    ></HeaderSubNavItem>
                  </ul>
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="industryPage in industryPagesTwoChunks[1]"
                      :key="industryPage.path"
                      :icon="industryPage.icon_class_name || 'iconoir-building'"
                      :title="industryPage.title"
                      :to="industryPage.path"
                    ></HeaderSubNavItem>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="saas-header__nav-item">
            <nuxt-link :to="{ name: 'pricing' }" class="saas-header__nav-link"
              >Pricing</nuxt-link
            >
          </li>
          <li class="saas-header__nav-item">
            <a
              class="saas-header__nav-link"
              :class="{
                'saas-header__nav-link--open': openSub === $refs.communitySub,
              }"
              @click="toggleSub($event, $refs.communitySub)"
            >
              Community
              <i class="saas-header__nav-link-more iconoir-nav-arrow-down"></i>
            </a>
            <div
              ref="communitySub"
              class="saas-header__sub"
              :class="{
                'saas-header__sub--open': openSub === $refs.communitySub,
              }"
            >
              <div class="saas-header__sub-section">
                <div class="saas-header__sub-navs">
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      v-for="communityPage in communityPages"
                      :key="communityPage.path"
                      icon="iconoir-group"
                      title="Get started"
                      description="Discover what the Baserow community is created for"
                      :to="communityPage.path"
                    ></HeaderSubNavItem>
                    <HeaderSubNavItem
                      icon="iconoir-chat-bubble-question"
                      title="Find answers"
                      description="Ask questions and get the most out of the platform"
                      href="https://community.baserow.io/"
                      target="_blank"
                    ></HeaderSubNavItem>
                    <HeaderSubNavItem
                      icon="iconoir-puzzle"
                      title="Partners"
                      description="Team up with our partners for various services."
                      :to="{ name: 'partners' }"
                      target="_blank"
                    ></HeaderSubNavItem>
                  </ul>
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      icon="iconoir-open-book"
                      title="Tips and tricks"
                      description="Take your data management expertise to the next level"
                      href="https://community.baserow.io/c/tips-and-tricks/16"
                      target="_blank"
                    ></HeaderSubNavItem>
                    <HeaderSubNavItem
                      icon="iconoir-light-bulb-on"
                      title="#MadeWithBaserow"
                      description="Get inspired by the projects from our community"
                      href="https://community.baserow.io/c/made-with-baserow/14"
                      target="_blank"
                    ></HeaderSubNavItem>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="saas-header__nav-item">
            <a
              class="saas-header__nav-link"
              :class="{
                'saas-header__nav-link--open': openSub === $refs.developersSub,
              }"
              @click="toggleSub($event, $refs.developersSub)"
            >
              Developers
              <i class="saas-header__nav-link-more iconoir-nav-arrow-down"></i>
            </a>
            <div
              ref="developersSub"
              class="saas-header__sub saas-header__sub--align-middle"
              :class="{
                'saas-header__sub--open': openSub === $refs.developersSub,
              }"
            >
              <div class="saas-header__sub-section">
                <div class="saas-header__sub-navs">
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      icon="iconoir-book"
                      title="Getting started"
                      :to="{
                        name: 'dev-docs',
                        params: {
                          path: 'index',
                        },
                      }"
                    ></HeaderSubNavItem>
                    <HeaderSubNavItem
                      icon="iconoir-ev-plug"
                      title="Creating a plugin"
                      :to="{
                        name: 'dev-docs',
                        params: { path: 'plugins/introduction' },
                      }"
                    ></HeaderSubNavItem>
                    <HeaderSubNavItem
                      icon="iconoir-server"
                      title="API"
                      :to="{ name: 'database-api-docs' }"
                    ></HeaderSubNavItem>
                  </ul>
                  <ul class="saas-header__sub-nav">
                    <HeaderSubNavItem
                      icon="iconoir-download"
                      title="Install with Docker"
                      :to="{
                        name: 'dev-docs',
                        params: { path: 'installation/install-with-docker' },
                      }"
                    ></HeaderSubNavItem>
                    <HeaderSubNavItem
                      icon="iconoir-arrow-right"
                      title="OpenAPI specification"
                      :href="$config.PUBLIC_BACKEND_URL + '/api/redoc/'"
                      target="_blank"
                    ></HeaderSubNavItem>
                  </ul>
                </div>
              </div>
              <div class="saas-header__sub-section">
                <ul class="saas-header__sub-nav">
                  <HeaderSubNavItem
                    icon="baserow-icon-gitlab"
                    title="GitLab repository"
                    href="https://gitlab.com/baserow/baserow"
                    rel="noopener noreferrer"
                    target="_blank"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-plus"
                    title="Want to contribute?"
                    :to="{
                      name: 'dev-docs',
                      params: { path: 'development/development-environment' },
                    }"
                  ></HeaderSubNavItem>
                </ul>
              </div>
            </div>
          </li>
          <li class="saas-header__nav-item">
            <a
              class="saas-header__nav-link"
              :class="{
                'saas-header__nav-link--open': openSub === $refs.resourcesSub,
              }"
              @click="toggleSub($event, $refs.resourcesSub)"
            >
              Resources
              <i class="saas-header__nav-link-more iconoir-nav-arrow-down"></i>
            </a>
            <div
              ref="resourcesSub"
              class="saas-header__sub saas-header__sub--align-right"
              :class="{
                'saas-header__sub--open': openSub === $refs.resourcesSub,
              }"
            >
              <div class="saas-header__sub-section">
                <div
                  class="saas-header__sub-title saas-header__sub-title--indent"
                >
                  Learn
                </div>
                <ul class="saas-header__sub-nav">
                  <HeaderSubNavItem
                    icon="iconoir-megaphone"
                    title="Blog"
                    :to="{ name: 'blog' }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-book"
                    title="Tutorials"
                    :to="{
                      name: 'blog-filtered',
                      params: { category: 'tutorials' },
                    }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-video-camera"
                    title="Videos"
                    :to="{
                      name: 'videos',
                    }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-binocular"
                    title="Knowledge base"
                    :to="{ name: 'user-docs' }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-graduation-cap"
                    title="Academy"
                    :to="{ name: 'academy' }"
                  ></HeaderSubNavItem>
                </ul>
              </div>
              <div class="saas-header__sub-section">
                <div
                  class="saas-header__sub-title saas-header__sub-title--indent"
                >
                  Inspiration
                </div>
                <ul class="saas-header__sub-nav">
                  <HeaderSubNavItem
                    icon="iconoir-box"
                    title="Templates"
                    :to="{ name: 'templates' }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-medal"
                    title="About us"
                    :to="{ name: 'about-us' }"
                  ></HeaderSubNavItem>
                </ul>
              </div>
              <div class="saas-header__sub-section">
                <div
                  class="saas-header__sub-title saas-header__sub-title--indent"
                >
                  Support
                </div>
                <ul class="saas-header__sub-nav">
                  <HeaderSubNavItem
                    icon="iconoir-message-text"
                    title="Contact us"
                    :to="{ name: 'contact' }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-warning-circle"
                    title="FAQ"
                    :to="{ name: 'faq' }"
                  ></HeaderSubNavItem>
                  <HeaderSubNavItem
                    icon="iconoir-puzzle"
                    title="Partners"
                    :to="{ name: 'partners' }"
                  ></HeaderSubNavItem>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <nav class="saas-header__nav saas-header__nav--last">
        <ul class="saas-header__nav-list">
          <li class="saas-header__nav-item margin-right-2">
            <nuxt-link
              :to="{ name: 'contact-sales' }"
              class="saas-header__nav-link"
            >
              Contact sales
            </nuxt-link>
          </li>
          <li
            v-if="!isAuthenticated"
            class="saas-header__nav-item margin-right-2"
          >
            <Button tag="nuxt-link" :to="{ name: 'login' }" type="secondary"
              >Login</Button
            >
          </li>
          <li v-if="!isAuthenticated" class="saas-header__nav-item">
            <Button tag="nuxt-link" :to="{ name: 'signup' }">Sign up</Button>
          </li>
          <li v-if="isAuthenticated" class="saas-header__nav-item">
            <Button tag="nuxt-link" :to="{ name: 'dashboard' }" type="secondary"
              >My dashboard</Button
            >
          </li>
        </ul>
      </nav>
      <a class="saas-header__nav-toggle" @click="showNav()">
        <i class="iconoir-menu"></i>
      </a>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { onClickOutside } from '@baserow/modules/core/utils/dom'
import HeaderSubNavItem from '@saas/components/header/HeaderSubNavItem'

export default {
  name: 'Header',
  components: { HeaderSubNavItem },
  data() {
    return {
      navVisible: false,
      openSub: null,
      lastSubClickOutsideCancel: null,
      menuOpenSub: [],
    }
  },
  computed: {
    homePages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.HomePage'
      )
    },
    productPages() {
      return this.$store.getters['saasLayout/getMenuChildPagesPerType'](
        'saas_cms.ProductListingPage'
      )
    },
    productPagesTwoChunks() {
      return this.splitChunk(this.productPages, 2)
    },
    departmentPages() {
      return this.$store.getters['saasLayout/getMenuChildPagesPerType'](
        'saas_cms.DepartmentListingPage'
      )
    },
    departmentPagesTwoChunks() {
      return this.splitChunk(this.departmentPages, 2)
    },
    industryPages() {
      return this.$store.getters['saasLayout/getMenuChildPagesPerType'](
        'saas_cms.IndustryListingPage'
      )
    },
    industryPagesTwoChunks() {
      return this.splitChunk(this.industryPages, 2)
    },
    aboutUsPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.AboutUsPage'
      )
    },
    jobListingPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.JobsListingPage'
      )
    },
    communityPages() {
      return this.$store.getters['saasLayout/getMenuPagesPerType'](
        'saas_cms.CommunityPage'
      )
    },
    ...mapGetters({
      navOpen: 'header/getNavOpen',
      topBars: 'header/getTopBars',
      isAuthenticated: 'auth/isAuthenticated',
      numberOfJobs: 'saasLayout/getNumberOfJobs',
    }),
  },
  watch: {
    $route(value) {
      this.hideNav()
      this.hideSub()
    },
  },
  methods: {
    toggleSub(event, targetElement) {
      event.stopPropagation()

      if (this.lastSubClickOutsideCancel !== null) {
        this.lastSubClickOutsideCancel()
        this.lastSubClickOutsideCancel = null
      }

      if (this.openSub === targetElement) {
        this.openSub = null
        return
      }

      this.openSub = targetElement
      this.lastSubClickOutsideCancel = onClickOutside(targetElement, () => {
        this.toggleSub(event, targetElement)
      })
      this.$once('hook:beforeDestroy', this.lastSubClickOutsideCancel)
    },
    hideSub() {
      if (this.lastSubClickOutsideCancel !== null) {
        this.lastSubClickOutsideCancel()
        this.lastSubClickOutsideCancel = null
      }

      this.openSub = null
    },
    toggleMenuSub(event, targetElement) {
      const index = this.menuOpenSub.findIndex((e) => e === targetElement)
      if (index < 0) {
        this.menuOpenSub.push(targetElement)
      } else {
        this.menuOpenSub.splice(index, 1)
      }
    },
    inMenuSub(targetElement) {
      return this.menuOpenSub.includes(targetElement)
    },
    showNav() {
      this.$store.dispatch('header/setNavOpen', true)
    },
    hideNav() {
      this.$store.dispatch('header/setNavOpen', false)
    },
    /**
     * Split an array into `size` number of chunks. This can be used to spread items
     * over two columns.
     */
    splitChunk(array, size = 2) {
      const response = []
      for (let i = 0; i < size; i++) {
        response.push([])
      }
      for (let i = 0; i < array.length; i++) {
        response[i % size].push(array[i])
      }
      return response
    },
  },
}
</script>
