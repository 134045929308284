var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"segment-control",class:{
    'segment-control--transparent': _vm.transparent,
    'segment-control--icons-only': _vm.iconsOnly,
    'segment-control--small': _vm.size === 'small',
    'segment-control--large': _vm.size === 'large',
    'segment-control--rounded': _vm.type === 'rounded',
  }},_vm._l((_vm.segments),function(segment,index){return _c('button',{key:index,staticClass:"segment-control__button",class:{
      'segment-control__button--active': index === _vm.activeIndex,
    },on:{"click":function($event){return _vm.setActiveIndex(index)}}},[(segment.icon)?_c('i',{class:segment.icon}):_vm._e(),_vm._v(" "),(segment.label)?_c('span',{staticClass:"segment-control__button-label"},[_vm._v(_vm._s(segment.label))]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }